.pagination-holder {
    display: flex;
    padding-left: 0;
    list-style: none;
    justify-content: flex-end;    
}
 
.pagination-btn{
    position: relative;
    display: block;    
    padding: 5px;
    margin-left: -1px;
    line-height: 1.25;
    color: #4f6d7a;
    background-color: white;
    border: 1px solid #dee2e6;
    cursor: pointer;
}

.pagination-btn:hover{
    background-color: #E9ECEF;
    color: #4f6d7a;
 }
 
.pagination-start{
    margin-left: 0;
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
}
 
.pagination-end{  
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
}

.pagination-link{ 
    text-decoration: none;
    color: #4f6d7a;
    font-size: 13px;
}
 
.pagination-link:hover{ 
    color: #4f6d7a;
}
 
.selected-page{ 
    background-color: #a9c9e4;
}

.out-of-range-hidden-page-btn{ 
    display: none;
 }