.jumto-result-link{
    text-decoration: none !important;
}

body .autosuggest-jumpto-container {
    position: relative;    
}

body .autosuggest-sticky{
    position: sticky !important;
    top: 0;
    z-index: 1000;
}

body .small-jumpto-box{
    width: 80%;
}

body .autosuggest-jumpto-container label {
    position: absolute;    
    top: 50%;
    transform: translateY(-50%);
    background-color: white; 
    padding: 2px 4px;
    margin-left: 1px;    
    z-index: 1;
    background-color: rgb(214, 214, 214);
}
  
body .autosuggest-jumpto-container input {
    padding-left: 80px; 
}

body .jumpto-wrapper{     
    margin-left: 30px;     
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;   
    z-index: 1000;
    background: white;
    height: 80px;     
}

body .jumpto-container{
    height: fit-content;
    padding-right: 10px;
    padding-left: 10px;
    text-align: left;
    background-color: white;
    position: relative;
    display: block;
    color: black;
}

body .jumpto-search-container{
    padding-bottom: 20px;
    padding-top: 10px;    
    z-index: 9999999;        
    width: 100%;        
}

body .jumpto-tree-container{ 
    top: auto;     
    border: 0.1px solid black;    
    padding-bottom: 10px;    
    z-index: 1000;        
    width: 400px;    
    margin-left: 85px;    
}

body .jumpto-item-holder{   
    margin-top: -15px;
    text-align: left;
}

body .jumpto-result-text{
    /* position:relative; */
    /* margin-left: 10px;
    margin-bottom: -30px;
    padding: 7px;  */
    padding: 10px;
}

body .jumpto-result-link:hover{
    text-decoration: none;
}

body .jumpto-result-text:hover{
    vertical-align: top;
    background-color: #dfdddd;
    text-decoration: none;
}


body .button-in-jumpto:hover{
    text-decoration: none;
    color: white;
}


body .jmp-ontology-button:hover{
    text-decoration: none;
    background-color: #bbae9e;
    color: white;
}

body .jumpto-search-box{
    display: block;
    height: calc(2.25rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
}

body .selected-by-arrow-key{
    background-color: #dfdddd;    
}



