body{
  color: #42484e !important;
  font-family: Arial, Helvetica, sans-serif !important;
  font-size: 16px !important;
  overflow-x: hidden !important;
}

a{
  /* 
     The style for the site anchors
  */
  padding-top: -30;
  color: #4f6d7a;
  text-decoration: none;
  /* font-weight: bold;  */
}

a:hover{
  /* 
     The style for the site anchors while hovering
  */

  text-decoration: underline;
  color: #e7eff3;
  /* font-weight: bold;  */
}

.application-content{
  overflow-x: hidden !important;
  overflow-y: hidden !important; 
  min-height: 500px !important;
  padding-top: 30px !important;
}

.ahome{  
  font-weight: bold !important;
}


.term-button{    
  background-color: #c0d6df !important;
  color: #4f6d7a !important;
  margin-left: 15px !important;
  padding: 3px !important;
  font-size: 12px !important;
  border-color:  #c0d6df !important;
  margin-top: -5px !important;
}

.term-button:hover{
  text-decoration: none !important;
  background-color: #4f6d7a !important;
  color: #eaeaea !important;
}

.ontology-button{ 
  background-color: #dd6e42 !important; 
  color: #eaeaea !important;
  margin-left: 10px !important;
  padding: 3px !important;
  font-size: 12px !important;
  margin-right: 10px !important;
  border-color: #dd6e42 !important;
}

.ontology-button:hover{ 
  text-decoration: none !important;
  background-color: #e8dab2 !important;
  color: #4f6d7a !important;
  border-color: #e8dab2 !important;
}

.facet-box{    
  background-color: white;
  padding-right: 10px;
  padding-top: 15px;
  border-radius: 10px;
  margin-bottom: 20px;
  padding-left: 10px;
  border: 1px solid #c0d6df !important;
}

.result-card{
  background-color: white !important;
  margin-bottom: 10px;
  padding: 10px;
  height: auto;
  text-align: justify;
  border: 1px solid #c0d6df !important;
}

.stats-box-text{    
  text-align: center;
}

.site-footer{
  background: white !important;   
  border-top: 1px #d1d3d9 solid !important;
  background-image: url('../../assets/img/polygon_background.svg') !important;
  background-size: cover !important;
  background-position: right center !important;
  padding-top: 20px !important;
  color: #4f6d7a !important;
  height: 200px !important;
  margin-top: auto !important;
}

.footer-funding-logo{
  /* 
      Logos on the site footer
  */

  width: 210px;
  height: 83px;
}

.footer-link{
  /* 
      Links in the footer
  */

  color: #4f6d7a;
}

.footer-link:hover{
  color: #c0d6df;
}

.site-dropdown-menu{ 
  border: 1px solid #00617C !important;
  padding: 5px;
}

.header-wrapper{
  background-position: right center !important;
  background-image: url('../../assets/img/polygon_background.svg') !important;
  background-size: cover !important;
  background-color: #4f6d7a !important;
  padding: 10px !important;
  color: #e8dab2 !important
}

.site-header-nav-logo-holder{
  margin-bottom: 30px !important;
  text-align: left !important;
}

.site-header-top-row{  
  text-align: right !important;
  color: white !important;
  margin-bottom: 20px !important;
}

.main-title {   
  color: #2f3e46 !important;
  text-decoration: none !important;
  font-size: 16px !important;  
  font-weight: bold !important; 
}

.main-title:hover {   
  color: black !important;
  text-decoration: none !important;
}
 
.site-logo-image{ 
  height: 80px !important;
  margin-left: 180px !important;
  margin-top: 0px !important;
  width: 200px !important;
  
}

.site-navbar { 
  margin-top: 15px !important;
  background-color: #ffffff00 !important;
  width: 90% !important;
}
 
.navbar-item {   
  margin-left: 10px !important;
  text-transform: uppercase !important;
  outline: none !important;
  color: #e8dab2 !important;
  font-weight: bold !important;
  font-size: 16px !important;
}

.navbar-item:hover{
  color: #dd6e42 !important;
  text-decoration: underline !important;
}

.home-image{
  /* 
      Homepage images size
  */

  height: 200px !important;
  width: 400px !important;
}

.home-page-stats-container{ 
  padding: 5% !important;
  padding-bottom: 8% !important;
  background-color: white !important;
}

.stats-box{ 
  color: #4f6d7a !important;
  font-size: 10px !important;
  background-color: white !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  text-align: center !important;
}

.autocomplete-item:hover{ 
  vertical-align: top !important;
  background-color: #f5f5f5 !important;
  width: 100% !important;
}

.jump-autocomplete-container{
  line-height: initial !important;
  height: 50px !important;
}

.search-input{
  border: 1px solid #404040 !important;
}

.search-btn{ 
  background-color: #c0d6df !important;
  color: #4f6d7a !important;   
}

.adv-search-container{
  text-align: left; 
  margin-left: 20% !important;    
}

.also-in-ontologies{    
  display: inline-block !important;
  padding-bottom: 7px !important;
}

.nav-tabs .ontology-detail-nav-item .nav-link.active {
  background-color: #FAFAFA !important;
}

.p-icon-style{     
  background-color: blue !important;
  color: white !important;
  padding: 5px !important;
  border-radius: 50px !important;
  font-size: 10px !important;
  margin-right: 5px !important;
  display: inline-block !important;
}

.site-header-search-filters-container{
  /* margin-left: 18% !important; */
  color: #e8dab2 !important;
}

.card-header{
  /* 
      The heading part style for the collapse button (bootstrap). Example: in the Usage page.
   */

  background-color: #c0d6df !important;
}

.card-body{
  padding: 1.25rem !important;
}

.collpase-text-btn{
  /* 
      The style for the collapse button (bootstrap). Example: in the Usage page.
   */

  background-color: #c0d6df !important;
  color: #4f6d7a !important;
  text-decoration: none !important;
  width: 100% !important;
  text-align: left !important;
  border-color: #d2e2e2 !important;
}
.collpase-text-btn:hover{
  /* 
      The style for the collapse button hover (bootstrap). Example: in the Usage page.
   */

  text-decoration: none !important;
  color: black !important;
}

.collpase-text-btn:focus{
  /* 
      Style for the collapse button when it is clicked (Example: in Usage page and Help page)
  */

  box-shadow: none !important;
  text-decoration: none !important;
  color: black !important;
}

.collapse-panel {
  height: 0;
  overflow: hidden;
  transition: height 0.5s;
  background-size: cover !important;
  background-position: right center !important;
}

.collapse-panel.expanded {
  height: 180px;
  transition: height 0.5s;
  background-size: cover !important;
  background-position: right center !important;
}

.examples{
  color:#eaeaea;
  text-shadow: 0 0 1px #c0d6df;

}

.example-link{
  color:#e8dab2;
}

.example-link:hover{
  color:#dd6e42;
}